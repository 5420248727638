import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "contact-attendees"
    }}>{`Contact Attendees`}</h1>
    <p>{`Let's explore how to bulk message members that have booked into an event.`}</p>
    <h2 {...{
      "id": "contact-attendees-1"
    }}>{`Contact Attendees`}</h2>
    <ol>
      <li parentName="ol">{`Open the event and select 'Edit Event'`}</li>
      <li parentName="ol">{`Enter your message in the Contact Attendees module`}</li>
      <li parentName="ol">{`Select 'Send Emails'`}</li>
      <li parentName="ol">{`Emails will only be sent to bookings with an email address that is linked in the LMS, the calendar will confirm how many emails were sent`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/8hOH3jhN_J4" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      